<template>
	<main class="page-container container">
		<div class="section__top mb-20">
			<div class="section__top-left">
				<AppText
					:size="isMobileSmall ? 18 : isMobile ? 22 : 26"
					weight="600"
					:line-height="isMobileSmall ? 22 : isMobile ? 28 : 34"
				>
					№{{ data.selectionNumber }}
				</AppText>
			</div>
		</div>

		<div class="section__body" :class="isMobileSmall ? 'mb-30' : 'mb-60'">
			<product-details-table :data="[]"></product-details-table>
			<div class="d-flex flex-column mb-20">
				<AppText class="mb-15" weight="500" size="13">
					Файлы для загрузки:
				</AppText>
				<div class="d-flex">
					<app-button-a
						class="mr-15 box-shadow fw-500"
						width=""
						theme="white"
						@click="download(file.url)"
					>
						test.pdf
						<img class="ml-15" width="12" height="15" src="@/assets/icons/download.svg" alt="" />
					</app-button-a>
				</div>
			</div>
			<div>
				<app-content-card class="custom-tab-component" padding-x="0" padding-y="0" :radius="[8, 8, 0, 0]">
					<app-tabs v-model="selectedTab" :list="tabItems" />
				</app-content-card>

				<customer-data-tab v-if="selectedTab === 'directContracts'" :data="[]"></customer-data-tab>
				<general-info-tab v-if="selectedTab === 'auction'" :data="[]"></general-info-tab>
				<criteria-tab v-if="selectedTab === 'criteria'" :data="[]"></criteria-tab>
				<contract-tab v-if="selectedTab === 'contract'" :data="[]"></contract-tab>
			</div>
		</div>
	</main>
</template>

<script>
import AppText from "@/components/shared-components/AppText";
import AppButtonA from "@/components/shared-components/AppButtonA";
import AppContentCard from "@/components/shared-components/AppContentCard";
import AppTabs from "@/components/shared-components/AppTabs";
import AppButton from "@/components/shared-components/AppButton";

import SelectionTimeCounter from "@/components/pages/selection/SelectionTimeCounter";
import ProductDetailsTable from "@/components/pages/selection/ProductDetailsTable";
import CustomerDataTab from "@/components/pages/selection/CustomerDataTab";
import GeneralInfoTab from "@/components/pages/selection/GeneralInfoTab";
import CriteriaTab from "@/components/pages/selection/CriteriaTab";
import ContractTab from "@/components/pages/selection/ContractTab";
import DownloadFiles from "@/components/pages/common/DownloadFiles";

export default {
	name: "tender-details",
	components: {
		AppText,
		AppButtonA,
		AppContentCard,
		AppTabs,
		AppButton,
		SelectionTimeCounter,
		ProductDetailsTable,
		CustomerDataTab,
		GeneralInfoTab,
		CriteriaTab,
		ContractTab,
		DownloadFiles
	},

	data() {
		return {
			tenderType: null,
			selectedTab: "directContracts",
			tabItems: [
				{
					name: this.$i18n.t("Информация о заказчике"),
					value: "directContracts"
				},
				{
					name: this.$i18n.t("Общие данные"),
					value: "auction"
				},
				{
					name: this.$i18n.t("Критерии"),
					value: "criteria"
				},
				{
					name: this.$i18n.t("Договор"),
					value: "contract"
				}
			],
			id: null,
			data: {},
			adminUrl: process.env.VUE_APP_ADMIN_URL
		};
	}
};
</script>

<style lang="scss" scoped>
.secondary-table {
	text-align: center;
	background: #fafdff;

	th {
		background: var(--color-secondary);
		color: white;
		text-align: center;
		padding: 22px;
		border-right: 1px solid #684687;
	}
}

.secondary-table th {
	padding: 15px;
}
.go-to-btn {
	// padding: 30px 0;
	border-radius: 8px;
	font-size: 14px;
	height: 40px;
	line-height: 18px;
	display: flex;
	align-items: center;
	padding: 15px 20px;
	font-weight: 600;
}
</style>
